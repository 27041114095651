.answer {
  font-weight: 500;
  text-transform: uppercase;
}

.container {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-bottom: 8px;
}

.note {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin: 0;

  span:last-child {
    margin-left: 3px;
  }
}

.noteFade {
  animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
