.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 20px;

  h1 {
    flex-grow: 1;
    font-size: 26px;
    margin: 0;

    @media screen and (min-width: 480px) {
      font-size: 30px;
    }
  }

  button {
    background-color: var(--white);
    border: 1px solid var(--black);
    border-radius: 5px;
    cursor: pointer;
  }
}

.left,
.right {
  width: 80px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;

  button:last-child {
    margin-left: 8px;
  }
}

button.back {
  margin-right: 15px;
  padding: 2px 5px 0 2px;

  & > svg {
    fill: var(--black);
    height: 20px;
    width: 20px;
  }

  @media screen and (min-width: 480px) {
    display: none;
  }
}

button.backDesktop {
  align-items: center;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: none;
  padding: 3px 11px 3px 3px;

  & > svg {
    fill: var(--black);
    height: 20px;
    width: 20px;
  }

  @media screen and (min-width: 480px) {
    display: flex;
  }
}

button.rules,
button.stats {
  padding: 2px 5px 0;

  & > svg {
    height: 20px;
    width: 20px;
  }
}
