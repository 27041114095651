.container {
  margin: 0 auto;
  padding: 0 5px;
}

.row {
  display: flex;
  justify-content: center;
  padding: 0 0 5px;

  @media screen and (min-width: 480px) {
    padding: 2px 0;
  }

  & > button {
    background-color: var(--grey-lighter);
    border: 1px solid var(--grey-light);
    color: var(--black);
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    margin-right: 2px;
    padding: 0;
    text-transform: capitalize;
    width: 34.5px;

    &:disabled {
      cursor: default;
    }

    @media screen and (min-width: 480px) {
      font-size: 18px;
      height: 46px;
      margin-right: 4px;
      width: 46px;
    }
  }

  & > button:last-child {
    margin-right: 0;
  }
}

button.backspace {
  padding-top: 5px;
  width: 42px;

  @media screen and (min-width: 480px) {
    width: 60px;
  }

  & > svg {
    height: 20px;
    width: 20px;

    @media screen and (min-width: 480px) {
      height: 24px;
      width: 24px;
    }
  }
}

button.enter {
  width: 64px;

  @media screen and (min-width: 480px) {
    width: 82px;
  }
}

button.absent {
  background-color: var(--grey);
  border-color: var(--grey);
  color: var(--white);
}

button.correct {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--white);
}

button.present {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--white);
}
