.chart {
  height: 150px !important;
  margin: 0 auto;
  width: 300px !important;
}

.container {
  text-align: center;
}

.stats {
  & > h3 {
    margin-top: 0;
  }

  & > p {
    margin: 10px 0;
  }

  & > p:last-child {
    font-size: 12px;
    margin: 20px 0 0;
  }
}
