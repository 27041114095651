.dualRing,
.dualRing:after {
  box-sizing: border-box;
}

.dualRing {
  color: var(--green-dark);
  display: inline-block;
  width: 80px;
  height: 80px;
}

.dualRing:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: dualRing 1.2s linear infinite;
}

@keyframes dualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
