.buttonContainer {
  text-align: right;
}

.close {
  background: var(--white);
  border: 1px solid var(--black);
  border-radius: 5px;
  cursor: pointer;
  padding: 2px 5px 0;

  & > svg {
    fill: var(--black);
    height: 20px;
    width: 20px;
  }
}

.container {
  background: rgba(248, 248, 255, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.content {
  max-width: 400px;
  background: var(--white);
  padding: 15px 15px 50px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
