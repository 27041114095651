@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400..700&display=swap');

:root {
  --black: #1d1d21;
  --green: #6aaa64;
  --green-dark: #228b22;
  --green-hover: rgba(106, 170, 100, 0.9);
  --grey: #787c7e;
  --grey-dark: #888888;
  --grey-light: #cccccc;
  --grey-lighter: #e9e9ec;
  --white: #f8f8ff;
  --yellow: #d2b55b;
}

body {
  background-color: var(--white);
  color: var(--black);
  margin: 0;
  font-family: 'Figtree', sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
