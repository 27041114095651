.grid {
  color: var(--black);
  display: grid;
  font-weight: 500;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 3px;
  height: 300px;
  margin: 0 auto 20px;
  width: 250px;

  @media screen and (min-width: 480px) {
    grid-gap: 5px;
    height: 375px;
    margin: 0 auto 40px;
    width: 320px;
  }
}

.letter {
  align-items: center;
  border: 1px solid var(--grey-light);
  display: inline-flex;
  font-size: 26px;
  justify-content: center;
  text-transform: uppercase;
  user-select: none;

  @media screen and (min-width: 480px) {
    font-size: 36px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3px;
  width: 100%;

  @media screen and (min-width: 480px) {
    grid-gap: 5px;
  }
}

.guessing {
  border: 1px solid var(--grey-dark);
}

.absent {
  background-color: var(--grey);
  color: var(--white);
}

.correct {
  background-color: var(--green);
  color: var(--white);
}

.default {
  background-color: var(--white);
  color: var(--black);
}

.present {
  background-color: var(--yellow);
  color: var(--white);
}

.invalid {
  animation: shake 0.25s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
