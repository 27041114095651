.content {
  padding: 0 20px;
}

.container {
  margin: 0 auto;
  padding: 15px 0;

  @media screen and (min-width: 480px) {
    padding: 30px 0;
  }
}

.stats {
  border: 1px solid var(--black);
  margin: 0 auto;
  max-width: 300px;
  padding: 20px;
}

.chart {
  height: 150px !important;
  margin: 0 auto;
  width: 300px !important;
}

button.play {
  background-color: var(--green);
  border: 2px solid var(--green-dark);
  border-radius: 30px;
  color: var(--white);
  cursor: pointer;
  font-family: 'Figtree', sans-serif;
  font-size: 24px;
  font-weight: 500;
  height: 62px;
  margin-bottom: 30px;
  width: 235px;

  &:hover {
    background-color: var(--green-hover);
  }
}
